<template>
  <div class="csv-table-wrapper">
    <div class="csv-table-wrapper-inner">
      <div class="table-responsive align-items-center" ref="tableContainer">
        <div class="table-wrapper">
          <table class="table mb-0" id="wizard-table">
            <thead class="has-gutter">
              <tr class="thead-light">
                <th v-for="(header, index) in contentHeaders" :key="index">
                  <div>{{ header }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in displayedRows" :key="index">
                <td v-for="(column, _index) in row" :key="_index">
                  <span>{{ column }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="table-footer">
      <base-pagination
        v-if="rowsCount > 0"
        class="pagination-no-border"
        v-model="actualPage"
        :per-page="perPage"
        :total="rowsCount"
      />
    </div>
  </div>
</template>

<script>
import { BasePagination } from "@/components";

export default {
  components: { BasePagination },

  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },

    contentHeaders: {
      type: Array,
      default: () => [],
    },
    contentRows: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      actualPage: this.currentPage,
    };
  },

  computed: {
    rowsCount() {
      return this.contentRows.length ?? 0;
    },

    displayedRows() {
      if (!this.contentRows) return [];

      const startIndex = (this.actualPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.contentRows.slice(startIndex, endIndex);
    },
  },

  mounted() {
    this.adjustTableHeight();
    window.addEventListener("resize", this.adjustTableHeight);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.adjustTableHeight);
  },

  methods: {
    adjustTableHeight() {
      const table = this.$el.querySelector("#wizard-table");

      const tableWrapper = this.$el.querySelector(".table-wrapper");
      tableWrapper.style.maxHeight = ""; //

      const tableHeight = table.offsetHeight;
      const windowHeight = window.innerHeight;

      if (tableHeight > windowHeight) {
        tableWrapper.style.maxHeight = windowHeight + "px";
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 100vh;
  overflow: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
}
</style>
