<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ `${importation.name}` }} - {{ $t("IMPORTS.IMPORTED_ITEMS") }}</h3>
    <div class="page-wrapper">
      <div class="page-wrapper-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div class="search-wrapper">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>

      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="pagedFilteredImportedItems"
          >
            <el-table-column
              :label="$t('IMPORTS.ITEM')"
              prop="id"
              sortable="id"
            >
              <template v-slot="{ row }">
                <router-link :to="$objectViewRoute(row)">
                  {{ $objectDenomination(row) }}
                </router-link>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="created_at"
              width="250px"
            />

            <el-table-column>
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_IMPORTS)"
                >
                  <a
                    type="text"
                    @click="viewItem(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-eye"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: importedItems.length ? from + 1 : 0,
            to: to,
            of: importedItems.length,
          })
        }}
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="filteredImportedItems.length"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { BasePagination } from "@/components";

export default {
  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ["importation"],

  data() {
    return {
      query: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      importedItems: Object.values(this.importation.importedItems),
    };
  },

  created() {},

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    pagedFilteredImportedItems() {
      return this.filteredImportedItems.slice(
        this.pagination.perPage * this.pagination.currentPage -
          this.pagination.perPage,
        this.pagination.perPage * this.pagination.currentPage
      );
    },

    filteredImportedItems() {
      return this.importedItems.filter((item) => {
        if (!this.query) {
          return true;
        }
        for (const key of Object.keys(item)) {
          if (typeof item[key] == "string") {
            const fieldStringValue = item[key].toLowerCase();
            if (fieldStringValue.includes(this.query.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
    },
  },

  methods: {
    viewItem(object) {
      this.$router.push(this.$objectViewRoute(object));
    },
  },

  watch: {
    importation(importation) {
      this.importedItems = Object.values(this.importation.importedItems);
    },
  },
};
</script>
