<template>
  <ImportWizardTable
    :content-rows="mappedData.rows"
    :content-headers="mappedData.headers"
  />
</template>

<script>
import ImportWizardTable from "@/views/Pages/AdminModule/ImportModule/partials/Wizard/ImportWizardTable.vue";

export default {
  components: { ImportWizardTable },

  props: {
    mapping: {
      type: Object,
      default: () => {},
    },
    uploadedData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      headers: [],
      rows: [],
    };
  },

  created() {
    this.handlePreview();
  },

  methods: {
    handlePreview() {
      const headers = Object.keys(this.mapping);
      const csv = this.uploadedData;

      const headerIndices = headers.map((header) =>
        csv.headers.indexOf(this.mapping[header])
      );

      this.headers = headers;
      const rows = csv.rows?.map((row) =>
        headerIndices.map((index) => row[index])
      );

      this.rows = rows;

      this.$emit("preview-data", rows);
    },
  },

  computed: {
    mappedData() {
      return { headers: this.headers, rows: this.rows };
    },
  },
};
</script>
