<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!importation">
      <span class="loader"></span>
    </span>
    <import-form
      v-if="importation"
      :loading="loading"
      :importData="importation"
      :formErrors="formErrors"
      @importSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import ImportForm from "@/views/Pages/AdminModule/ImportModule/partials/ImportForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { ImportForm },

  mixins: [alertLeave],

  props: {
    importationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      importation: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("imports/get", this.importationId);
        this.importation = this.$store.getters["imports/import"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(importation) {
      this.loading = true;

      const importationData = cloneDeep(importation);

      try {
        await this.$store.dispatch("imports/update", importationData);
        this.$notify({
          type: "success",
          message: this.$t("IMPORTS.IMPORT_UPDATED"),
        });
        const location = this.$store.getters["imports/import"];
        this.$emit("onViewImportation", location, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
