<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ `${importation.name}` }} - {{ $t("COMMON.LOGS") }}</h3>
    </div>
    <log-list-table :filterSubject="importation" />
  </div>
</template>

<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";
export default {
  name: "import-view-logs",

  components: { LogListTable },

  props: ["importation"],

  watch: {
    user(user) {},
  },
};
</script>
