<template>
  <form
    class="add-form"
    ref="importForm"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <!-- Organization -->
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="importation.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
              importation.organization.id = organizationId;
              onFormChanged();
            }
          "
          :disabled="!!importation.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <!-- Imports model -->
    <div class="form-wrapper">
      <base-input
        :label="`${$t('IMPORTS.IMPORT_MODEL')} (*)`"
        :placeholder="`${$t('IMPORTS.CHOOSE_MODEL')}`"
        @change="onFormChanged()"
      >
        <import-model-selector
          :models="models"
          @importModelChanged="
            (model) => {
              importation.model = model.value;
              importation.name = `${model.value} ${formatDate()}`;
              templateFields = model.fields;
            }
          "
        />
      </base-input>
    </div>

    <!-- Imports name -->
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('IMPORTS.IMPORT_NAME')} (*)`"
        v-model="importation.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="importation.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="form-wrapper full">
      <CsvUploader
        ref="uploadedFile"
        @csv-uploaded="handleUpload"
        @get-csv-file="getUploadFile"
      />
    </div>

    <div class="csv-table">
      <ImportFirstStep
        :headers="uploadedData.headers"
        :rows="uploadedData.rows"
        v-if="currentStep === 'first'"
      />

      <ImportSecondStep
        @mapping-result="handleMapping"
        :model-headers="templateFields"
        :csv-headers="uploadedData.headers"
        v-if="currentStep === 'second'"
      />

      <ImportThirdStep
        @preview-data="handlePreviewData"
        :mapping="mapping"
        :uploaded-data="uploadedData"
        v-if="currentStep === 'third'"
      />
    </div>

    <div class="step-buttons">
      <base-button
        v-if="currentStep !== 'first'"
        type="button"
        class="btn btn-sm btn-warning"
        @click.prevent="prevStep"
      >
        {{ $t("COMMON.PREV_STEP") }}
      </base-button>

      <base-button
        v-if="currentStep !== 'third' && Object.keys(uploadedData).length !== 0"
        type="button"
        class="btn btn-sm btn-default"
        @click.prevent="nextStep"
      >
        {{ $t("COMMON.NEXT_STEP") }}
      </base-button>

      <base-button
        v-if="currentStep !== 'first'"
        type="button"
        class="btn btn-sm btn-danger"
        :disabled="loading"
        @click.prevent="cancel"
      >
        {{ $t("COMMON.CANCEL") }}
      </base-button>

      <base-button
        v-if="currentStep === 'third'"
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          importation.id ? $t("IMPORTS.EDIT_IMPORT") : $t("IMPORTS.ADD_IMPORT")
        }}
      </base-button>
    </div>
  </form>
</template>

<script>
import moment from "moment";

import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ImportModelSelector from "@/components/ImportModelSelector.vue";
import CsvUploader from "@/components/CsvUploader.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ImportFirstStep from "@/views/Pages/AdminModule/ImportModule/partials/Wizard/ImportFirstStep.vue";
import ImportSecondStep from "@/views/Pages/AdminModule/ImportModule/partials/Wizard/ImportSecondStep.vue";
import ImportThirdStep from "@/views/Pages/AdminModule/ImportModule/partials/Wizard/ImportThirdStep.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ImportModelSelector,
    CsvUploader,
    ImportFirstStep,
    ImportSecondStep,
    ImportThirdStep,
    HtmlEditor,
  },

  mixins: [formMixin],

  props: ["importData", "formErrors", "perPage"],

  data() {
    return {
      importation: { ...this.importData },

      loading: false,
      models: [],
      templateFields: [],

      uploadedData: {},
      uploadedFile: {},
      mapping: {},
      preview: {},
      currentStep: "first",
    };
  },

  mounted() {
    this.loadModels();
  },

  methods: {
    formatDate() {
      return moment(new Date()).format("DD-MM-YYYY HH:mm:ss:SSSS");
    },

    async loadModels() {
      try {
        await this.$store.dispatch("imports/models");
        this.models = this.$store.getters["imports/models"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async handleSubmit() {
      this.importation = {
        ...this.importation,
        mapping: this.mapping,
        file: this.uploadedFile,
      };
      this.$emit("importSubmitted", this.importation);
    },

    handleUpload(data) {
      this.uploadedData = data;
    },

    getUploadFile(file) {
      this.uploadedFile = file;
    },

    handleMapping(result) {
      this.mapping = result;
    },

    handlePreviewData(data) {
      this.preview = data;
    },

    nextStep() {
      if (this.currentStep === "first") {
        this.currentStep = "second";
      } else if (this.currentStep === "second") {
        this.currentStep = "third";
      }
    },

    prevStep() {
      if (this.currentStep === "second") {
        this.currentStep = "first";
      } else if (this.currentStep === "third") {
        this.currentStep = "second";
      }
    },

    cancel() {
      this.importation = { ...this.importData };
      this.uploadedData = {};
      this.uploadedFile = {};
      this.templateFields = [];
      this.currentStep = "first";
      this.$refs.importForm.reset();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },
};
</script>
