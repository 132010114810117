export default {
  type: "imports",
  name: null,
  model: null,
  excerpt: "",
  results: {},
  mapping: {},
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
};
