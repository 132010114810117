<template>
  <div class="csv-uploader-part-two">
    <div class="table-responsive">
      <table class="table">
        <thead class="has-gutter">
          <tr class="thead-light align-center text-center">
            <th>Import Model Fields</th>
            <th>Columns in your file</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(template, index) in modelHeaders"
            :key="index"
            class="align-center text-center"
          >
            <td>
              {{ template.name }}
              <span v-if="template.required" class="text-danger"> (*) </span>
            </td>

            <td>
              <select
                v-model="selectedFields[index]"
                @change.prevent="mapFields"
              >
                <option value="" selected>Select a column</option>
                <option
                  v-for="(fileColumn, _index) in csvHeaders"
                  :key="_index"
                  :value="fileColumn"
                  :disabled="fieldSelected(fileColumn, index)"
                >
                  {{ fileColumn }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    csvHeaders: {
      type: Array,
      required: true,
    },

    modelHeaders: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedFields: [],
      result: {},
    };
  },

  methods: {
    fieldSelected(field, currentIndex) {
      return this.selectedFields.some(
        (selected, index) => selected === field && index !== currentIndex
      );
    },

    mapFields() {
      for (const [index, object] of Object.entries(this.modelHeaders)) {
        const templateColumn = object.code;
        const selectedField = this.selectedFields[Number(index)];

        if (selectedField) this.result[templateColumn] = selectedField;
      }

      const data = { ...this.result };
      this.$emit("mapping-result", data);
    },
  },
};
</script>
